<template>
  <div class="min-h-screen">
    <PlacesDialouge :force-selector="true" :hide-activator="true" />
    <div v-if="store">
      <!-- Store Image -->
      <div class="relative">
        <StoresSlider :store="store" />
      </div>

      <StoresMetaTile :store="store" />

      <HomeSwiperMaterials
        :branch_id="currentBranchId"
        :fetch-route="deliveringRoutes.materials"
        :title="t('offers_discounts')"
        :is-delivering="true"
        :fetch-params="{
          store_id: store.id,
          offersOnly: true,
          branch_id: currentBranchId
        }"
      />

      <HomeOffersGroupSlider
        :query="{
          'store_ids[]': [store.id],
          limit: 1000,
          branch_id: currentBranchId
        }"
      />
      <MaterialsCatogriesWithSupportFilter
        :support-filter="true"
        :categories="categories || undefined"
        :store-type-id="store.type_id"
        :branch-id="currentBranchId"
        :fetch-params="{ store_id: store.id, branch_id: currentBranchId }"
      />

      <MaterialsGrid
        v-if="enableFetch"
        :items-per-page="20"
        with-query-params
        :is-delivering="true"
        :fetch-route="deliveringRoutes.materialsByServer"
        :branch-id="currentBranchId"
        :fetch-params="{ store_id: store.id,branch_id: currentBranchId }"
        class="mb-5 mevcut-container"
        manual
      />
      <HomeSwiperMaterials
        :branch-id="currentBranchId"
        :title="t('featured_materials')"
        :is-delivering="true"
        :fetch-route="deliveringRoutes.materials"
        :fetch-params="{
          is_featured: true,
          store_id: store.id,
          branch_id: currentBranchId
        }"
      />
      <StoresRelatedStores :related-stores="store.related_stores" />
      <HomeAdSecondarySwiper
        :ads="{ error: announcementsError, items: announcements }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FetchError } from 'ofetch'
import getAllRoutes from '~/composables/AppApiRoutes'
import type { Store, Category, Ad } from '~~/composables/useMenuModel'
const { url } = useDomainHost()
const { storeModel, categoryModel, adModel } = useMenuModel()
const route = useRoute()
const { t } = useI18n()
const store = ref<Store | null>(null)
const categories = ref<Category[] | null>(null)
const announcements = ref<Ad[] | null>(null)
const storeError = ref<FetchError<any> | null>(null)
const announcementsError = ref<FetchError<any> | null>(null)
const currentBranchId = useBranchId()
const enableFetch = computed(() => {
  return store.value?.enableBranches ? currentBranchId : true
})

const deliveringRoutes = getAllRoutes().materialsRoutes.deliveringRoutes
async function fetchCategoriesAndAnnouncements() {
  if (enableFetch.value) {
    const { data: categoriesTemp } = await useBasicFetch(
      getAllRoutes().categoriesRoutes.categoriesByServer,
      {
        query: {
          store_id: store.value?.id,
          store_type_id: store.value?.type_id,
          branch_id: currentBranchId,
          limit: 100,
          with_children: true,
          only_has_material: true,
          children_count: true
        },
        transform: (data: SuccessResponse<Category[]>) =>
          data.data.map(categoryModel)
      }
    )

    const { data: ads, error: errorAds } = await useBasicFetch(
      getAllRoutes().announcementRoutes.announcementsStore(store.value?.id),
      {
        query: {
          branch_id: currentBranchId
        },
        transform: (
          data: SuccessResponse<{
          announcementsStore: Ad[]
          announcements: Ad[]
        }>
        ) => {
          const adsStore = data.data.announcementsStore.map((ad) => adModel(ad, true))

          const adsPublic = data.data.announcements.map((ad) => adModel(ad, false))

          return [...adsPublic, ...adsStore]
        }
      }
    )
    announcements.value = ads.value

    categories.value = categoriesTemp.value
    announcementsError.value = errorAds.value
  }
}

async function fetchStore() {
  const { data: storeTemp, error: errorTemp } = await useBasicFetch(
    getAllRoutes().storesRoutes.storeShowByServer,
    {
      query: { id: route.params.id },

      transform: (data: SuccessResponse<Store>) => data.data
    }
  )

  useApiError(errorTemp.value)
  storeError.value = errorTemp.value

  useServerSeoMeta(
    useOgMeta(
      url + '/delivering/stores/' + route.params.id,
      storeTemp.value?.name,
      storeTemp.value?.about,
      storeTemp.value?.thumb
    )
  )

  useHead({
    title: storeTemp.value?.name,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: storeTemp.value?.about
      }
    ],
    link: [useSeoCanonical(url + '/delivering/stores/' + route.params.id)],
    script: [
      useSeoBreadcrumb([
        useSeoBreadcrumbItem(1, t('home'), url),
        useSeoBreadcrumbItem(2, t('at_yours'), url + '/delivering'),
        useSeoBreadcrumbItem(3, t('stores'), url + '/delivering/stores'),
        useSeoBreadcrumbItem(
          4,
          storeTemp.value?.name,
          url + '/delivering/stores/' + route.params.id
        )
      ])
    ]
  })
  return storeTemp
}

const storeTemp = await fetchStore()
if (storeTemp.value) {
  store.value = storeModel(storeTemp.value)
  await fetchCategoriesAndAnnouncements()
}
</script>
